import { graphql } from 'gatsby'
import React from 'react'

import Article from '../screens/article'

const Post = (props) => {
  const article = props.data.wpNftStory
  return <Article {...props} article={article} category="nft-stories" />
}

export default Post

export const pageQuery = graphql`
  query NftStoryById($slug: String!, $lang: String) {
    wpNftStory(locale: { locale: { eq: $lang } }, slug: { eq: $slug }) {
      id
      title
      content
      date(formatString: "MMM DD, YYYY")
      dateGmt
      slug
      status
      excerpt
      nodeType
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
          slug
          description
          id
        }
      }
      acf: acfNftStories {
        articleExcerpt
      }
      featuredImage {
        node {
          caption
          sourceUrl

        }
      }
    }
  }
`
